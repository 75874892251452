import { html, css, LitElement } from 'lit';
// eslint-disable-next-line import/extensions
import { customElement } from 'lit/decorators.js';
import { registerSW } from 'virtual:pwa-register';
import { routes } from './router/routes';

import '@kisters/wcp-base/app';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateSW = registerSW({
  onOfflineReady() {
    // eslint-disable-next-line no-console
    console.log('offline ready!');
  },
});

@customElement('app-index')
export class AppIndex extends LitElement {
  get _mainElement() {
    return this.shadowRoot.querySelector('.main');
  }

  static get styles() {
    return css`
      :host {
        height: 100%;
        overflow: scroll;
      }
    `;
  }

  render() {
    // language=html
    return html`
      <ki-app-index
        .baseUrl="${import.meta.env.VITE_BASE_URL.length > 1 &&
        import.meta.env.VITE_BASE_URL[
          import.meta.env.VITE_BASE_URL.length - 1
        ] === '/'
          ? import.meta.env.VITE_BASE_URL.slice(0, -1)
          : import.meta.env.VITE_BASE_URL}"
        .route="${routes}"
        .themable="${true}"
      ></ki-app-index>
    `;
  }
}
