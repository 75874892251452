export const routes = [
  {
    path: '/',
    component: 'cpcb-dashboard',
    action: async () => {
      await import('../app/cpcb-dashboard-app');
    },
    children: [
      {
        path: '/',
        name: 'index',
        component: 'page-index',
        action: async () => {
          await import('../pages/page-index');
        },
      },
      {
        path: '(.*)',
        name: 'not-found',
        component: 'page-not-found',
        action: async () => {
          await import('../pages/page-not-found');
        },
      },
    ],
  },
];
